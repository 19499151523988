/* redirect to home page instead of displaying 404 page */

import { useEffect } from "react"
import { navigate } from "gatsby"

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/")
  }, [])
  return null
}

export default NotFoundPage

// import React from "react"
// import styled from "styled-components"
// import Layout from "../components/layout"
// import Seo from "../components/seo"
// import ContentContainer from "../components-for-contents/ContentContainer"

// const NotFoundPage = () => {
//   return (
//     <Layout>
//       <Seo title="404: Not found" />
//       <NotFoundPageWrapper>
//         <section>
//           <ContentContainer>
//             <h2>404 NOT FOUND</h2>
//             <p>The page you're looking for does not exist.</p>
//           </ContentContainer>
//         </section>
//       </NotFoundPageWrapper>
//     </Layout>
//   )
// }

// export default NotFoundPage

// const NotFoundPageWrapper = styled.div``
